.servicepage-body{
    background-image: url(../../images/background.png);
    /* Full height */
    height: 47.1rem; 

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
}
.servicepage-content-wrapper{
    color: whitesmoke;
    text-align: left;
    margin-left: 10%;
    margin-top: 8%;
}
.service_mmhd_logo{
    margin-left: 1rem;
}
.mmhd_logo_dashboard {
    height: 2.5rem;
    margin-left: -0.6rem;
}
.onyx_logo_landing2 {
    margin-top: -0.5rem;
}
.power_by {
    color: white;
    font-size: 0.6rem;
    display: inline-block
}
.onyx_logo_landing2 {
    margin-top: -0.5rem;
}